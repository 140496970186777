import React, { Component } from 'react';
import Layout from '../components/layout'
import styled from 'styled-components'
import Loader from 'react-loader-spinner'

import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts'

import { GetNewUsers, GetTotalEventViews } from '../services/parse-bridge'

const TicketChartContainer = styled.div`
    margin: 50px 0;
`
const SectionTitle = styled.h2``

const Spinner = styled.div`
    text-align: center;
    margin: 15px 0;
`

class AnalyticsPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            eventStats: [],
            userStats: [],
            eventsLoading: true,
            usersLoading: true,
        }
    }

    componentDidMount() {
        GetNewUsers().then(res=> {
            console.log(res)
            this.setState({
                userStats: res,
                usersLoading: false,
            })
        })
        GetTotalEventViews().then(res=>{
            console.log(res)
            this.setState({
                eventStats: res,
                eventsLoading: false

            })
        })

    }

    render() {
        return (
            <Layout>
                <TicketChartContainer>
                    <SectionTitle>Users this week</SectionTitle>
                    {this.state.usersLoading ? <Spinner><Loader type="Oval" color="#Fc444e" height="40" width="40" /></Spinner> : 
                    <ResponsiveContainer width={'100%'} height={300}>
                        <LineChart
                            width={600}
                            height={300}
                            data={this.state.userStats.reverse()}
                            margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                            <XAxis dataKey="date" />
                            <YAxis />
                            <CartesianGrid opacity={0.5} strokeDasharray="4 4" />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="totalUsers" stroke="#fc444e" activeDot={{ r: 8 }} />
                        </LineChart>
                    </ResponsiveContainer>
                }
                </TicketChartContainer>
                <TicketChartContainer>
                    <SectionTitle>Event views this week</SectionTitle>
                    {this.state.eventsLoading ? <Spinner><Loader type="Oval" color="#Fc444e" height="40" width="40" /></Spinner> : 
                    <ResponsiveContainer width={'100%'} height={300}>
                        <LineChart
                            width={600}
                            height={300}
                            data={this.state.eventStats.reverse()}
                            margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                            <XAxis dataKey="date" />
                            <YAxis />
                            <CartesianGrid opacity={0.5} strokeDasharray="4 4" />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="totalViews" stroke="#fc444e" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="droidViews" stroke="#82ca9d" />
                            <Line type="monotone" dataKey="iosViews" stroke="#000" />
                        </LineChart>
                    </ResponsiveContainer>
                }
                </TicketChartContainer>
            </Layout>);
    }
}

const data = [
    { name: 'Mon 1st', ticketsSold: 20 },
    { name: 'Tue 2nd', ticketsSold: 24 },
    { name: 'Wed 3rd', ticketsSold: 54 },
    { name: 'Thu 4th', ticketsSold: 0 },
    { name: 'Fri 5th', ticketsSold: 154 },
    { name: 'Sat 6th', ticketsSold: 12 },
    { name: 'Sun 7th', ticketsSold: 240 },
];

export default AnalyticsPage;